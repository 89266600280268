<template>
 <div>
    <v-row no-gutters>
        <v-col cols="12">
          <v-card v-if="!isOnline" flat class="text-center pa-4">
            
            <p style="margin-top: 120px;">
              <v-btn  x-large fab><v-icon>mdi-wifi-off</v-icon></v-btn>
            </p>
              Please connect to the Internet 
          </v-card>
        <template v-else>
          <!-- <v-toolbar dense  dark color="#222222">
                   <v-icon color="warning" @click="$router.go(-1)" left>mdi-arrow-left</v-icon> 
                   Agents/Players
                   <v-spacer />
                   <v-icon color="success" @click="state='new',form=true">mdi-plus-circle</v-icon>
                </v-toolbar> -->
          <v-form  
              
                ref="form"
                v-model="valid"
                lazy-validation
                 autocomplete="off"
                style="width: 100%; margin: auto;"
              >
            <v-card tile class="pa-1">
               <!-- <v-text-field 
                    v-model.trim="search"
                    label="Search Account"
                    dense
                    hide-details=""
                    outlined
                /> -->
                  <table class="print_table" v-if="players.length>0">
                        <tr v-for="(item,index) in players" :key="index" :class="'td-'+IS_ODD(index)">
                             <td class="pa-2" >
                              <span style="float: right">
                                   <v-btn x-small text color="info" class="ma-1" fab @click="state='update', account= item, form=true"><v-icon>mdi-account-cog-outline</v-icon></v-btn>
                                   <v-btn class="ma-1" text x-small color="accent" fab @click="account= item, transform=true"><v-icon small>mdi-format-list-text</v-icon></v-btn>
                                  </span>
                              {{ item.fullname.toUpperCase() }}
                                <span class="font-weight-light text-info">- {{item.role}}</span>
                                <br />
                                <v-icon color="warning" left small>mdi-cellphone-basic</v-icon><span class="font-weight-light">{{item.contact_no}}</span>
                            
                                 
                                  </td>

                        </tr>
                      </table>    
                    <v-alert v-else color="info" info dark class="text-center">
                      Not Records found!
                    </v-alert>
            </v-card>
          </v-form>
        </template>
        </v-col>
    </v-row>
 <va-account-form :show="form" :state="state" :data="account" @DialogEvent="fEvent"/>
 <reports-account-transaction :show="transform" :data="account" @DialogEvent="tEvent"/>
</div>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    data: () => ({
        state: "new",
        form: false,
        transform: false,
      to_menu: false,
      loading: false,
      valid: false,
      account: {},
      accountfound: false,
        search:'',
        players:[],
        amount:null,
        from_date:'',
        account_no:null,
        headers: [
               { text: 'Name', value:"fullname"},
                  { text: 'action', value:"fullname"},
        ],
        ticket_code:"",
         nameRules: [
        v => !!v || 'Field is required'
      ],
    }),
    beforeDestroy(){
      console.log("beforeDestroy")
      //this.setToolbar(true)
    },
    created() {
      this.PING()
        this.setDrawer(false)
      // this.setLoggedIn(true)
       this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.token
        this.getPlayers()
       // this.setToolbar(false)
        
    },
     mounted(){
       console.log("mounted")
       this.setLoggedIn(true)
        if (this.user == null) {
          this.$router.push('/')
        }else {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.user.token
        this.setOverlayLoading(false)
        }
    },
    computed:{
       windowClass() {
        if(this.$store.state.responsive){
          return "va-reponsive"
        } else {
          return "va-not-reponsive"
        }
      },
      isOnline(){
        return this.$store.state.is_online
      },
      user() {
        if(localStorage.user){
            return JSON.parse(localStorage.user)
        }
        return null
      }
    },
    methods: {
      ...mapMutations(['setDrawer', 'setAlertColor', 'setAlertText', 'setLoggedIn']),
      tEvent(){
        this.transform = false
      },
      fEvent(){
          this.form = false
            this.getPlayers()
      },
       validate () {
           
      if (this.$refs.form.validate()) {
         this.addLoad()
       }
      },
      getPlayers(){
          console.log({id: this.$IsNum(this.user.id), role: this.user.role})
         this.$http.post("account/get_players",{id: this.$IsNum(this.user.id), role: "Coordinator"}).then(response => {
            response.data.list != null?this.players=response.data.list:this.players = []
            console.log(response.data.list)
          }).catch(e => {
            console.log(e.data)
          })
      },
       userDetails(){
          this.account = {}
          this.accountfound = false
          this.$http.post("user/details",{id: this.$IsNum(this.account_no)}).then(response => {
              if(response.data.status) {
                 this.account = response.data.account
                  this.accountfound = true
              } else {
                this.VA_ALERT("error", response.data.message)
              }
          }).catch(e => {
            console.log(e.data)
          })
    },
      addLoad(){
        var param = {}
        param.type = "Transfer Load"
        param.description = "Transfer Load"
        param.amount = this.$IsNum(this.amount.trim())
        param.account = this.$IsNum(this.account_no.trim())
        this.$http.post("wallet/load",param).then(response => {
              if(response.data.status) {
                  this.VA_ALERT('success', response.data.message)
                  this.amount = 0
                  this.account_no = 0
                  this.accountfound = false
                  this.$refs.form.reset()
              } else {
                  this.VA_ALERT('error', response.data.message)
              }
          }).catch(e => {
            console.log(e.data)
          })
      }
    },
  }
</script>